import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "../settings.module.css";
import { AuthContext } from "../../../../context/AuthContext";
import { PaymentContext } from "../../../../context/PaymentContext";
import AddIcon from "../../../../assets/SVG/AddIcon";

import UpgradePopUp from "../../../PopUpComponents/UpgradePopup/UpgradePopup";

export default function SubscriptionSettings({ profile }) {
  const {
    fetchPaymentPortalLink,
    customerInformation,
    productPlans,
    currentPlan,
    setCurrentPlan,
  } = useContext(PaymentContext);

  const [otherPlans, setOtherPlans] = useState([]);

  const [openPopup, setOpenPopup] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  function handleProviderSelect(provider) {
    // connectAccount(provider);
  }

  function openUpgradePopup() {
    setOpenPopup(true);
  }

  function closePopup() {
    setOpenPopup(false);
  }

  const freePlan = profile.subscription_plan === "free";

  useEffect(() => {
    if (customerInformation && customerInformation?.subscriptions?.length > 0) {
      if (currentPlan) {
        const currentPlanIndex = productPlans.indexOf(currentPlan);
        const otherPlansFromProductPlans = productPlans.slice(
          currentPlanIndex + 1
        );
        setOtherPlans(otherPlansFromProductPlans);
      } else {
        setOtherPlans([]);
      }
    } else {
      setOtherPlans(productPlans);
    }
  }, [customerInformation, productPlans]);

  async function handleManageSubscription() {
    try {
      const portalURL = await fetchPaymentPortalLink(
        profile.uid,
        profile.stripe_id
      );
      console.log("Payment Portal URL:", portalURL);

      window.open(portalURL, "_blank");
    } catch (error) {
      console.error("Error:", error);
    }
  }

  function CardBrandImage() {}

  function PaymentInformation() {
    return <div className={styles.payment_option}></div>;
  }

  function handleEditSubscription() {
    if (freePlan) {
      openUpgradePopup();
    } else {
      handleManageSubscription();
    }
  }

  return (
    <div className={styles.main_layout}>
      <UpgradePopUp
        isOpen={openPopup}
        onClose={closePopup}
        onProviderSelect={handleProviderSelect}
        isLoading={isLoading}
      />

      <p className={styles.top_section_title}>Plan & Billing</p>
      <div className={styles.plan_information}>
        <p className={styles.payment_section_title}>Plan Information</p>
        <div
          className={styles.manage_payment_button}
          onClick={handleEditSubscription}
        >
          Manage Subscription
        </div>
        <div className={styles.plan_information_top}>
          <div className={styles.subscription_options}>
            {freePlan ? (
              <FreePlanTile />
            ) : (
              <CurrentPlanTile currentPlan={currentPlan} />
            )}

            {otherPlans?.map((plan, index) => {
              console.log("plan", plan);
              const price = plan.prices.find(
                (price) => price.nickname === "monthly"
              );
              const formattedPrice = price
                ? `${(price.unit_amount / 100).toFixed(2)}`
                : "N/A";
              return (
                <div className={styles.plan_option} key={index}>
                  <div className={styles.plan_option_top_information}>
                    <p className={styles.plan_option_title}>
                      {extractFirstWord(plan?.name)}
                    </p>
                    <p className={styles.plan_option_price}>
                      ${formattedPrice ? formattedPrice : "N/A"}/month
                    </p>
                  </div>
                  <p className={styles.plan_option_subtitle}>
                    {/* {plan?.metadata?.mailbox_limit} Email,{" "} */}
                    {plan?.metadata?.contact_search_limit} Contacts{" "}
                    {/* {plan?.metadata?.ai_credit_limit} Ai Credits */}
                  </p>
                  <div className={styles.plan_option_bottom_information}>
                    <div
                      className={styles.plan_option_status_upgrade}
                      onClick={handleEditSubscription}
                    >
                      Upgrade Plan
                    </div>
                    <div className={styles.plan_data_icons}>
                      <img
                        alt="logo"
                        src="/SocialMediaLogos/instagram.png"
                        className={styles.data_icon}
                      ></img>
                      {plan?.name !== "Lite Plan" && (
                        <img
                          alt="logo"
                          src="/SocialMediaLogos/tiktok.png"
                          className={styles.data_icon}
                        ></img>
                      )}
                      {plan?.name !== "Lite Plan" && (
                        <img
                          alt="logo"
                          src="/SocialMediaLogos/youtube.png"
                          className={styles.youtube_icon}
                        ></img>
                      )}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className={styles.plan_information_divider}></div>
        <div className={styles.plan_information_bottom}>
          {/* <div className={styles.account_limit}>
            <p className={styles.account_limit_title}>Emails Connected</p>
            <div className={styles.progress_line_background}>
              <div
                className={styles.progress_line}
                style={{
                  width: `${
                    ((profile?.connected_emails?.length || 0) /
                      (currentPlan?.metadata?.mailbox_limit || 1)) *
                    100
                  }%`,
                }}
              ></div>
            </div>
            <div className={styles.limit_info}>
              {profile?.connected_emails?.length || 0} /{" "}
              {currentPlan?.metadata?.mailbox_limit}
              {otherPlans.length !== 0 && (
                <p
                  className={styles.upgrade_text_button}
                  onClick={handleManageSubscription}
                >
                  Upgrade
                </p>
              )}
            </div>
          </div> */}
          <div className={styles.account_limit}>
            <p className={styles.account_limit_title}>Contacts Searched</p>
            <div className={styles.progress_line_background}>
              <div
                className={styles.progress_line}
                style={{
                  width: `${
                    ((profile?.contacts_searched || 0) /
                      (currentPlan?.metadata?.contact_search_limit || 1)) *
                    100
                  }%`,
                }}
              ></div>
            </div>
            <div className={styles.limit_info}>
              {profile?.contacts_searched || 0} /{" "}
              {currentPlan?.metadata?.contact_search_limit}
              {otherPlans.length !== 0 && (
                <p
                  className={styles.upgrade_text_button}
                  onClick={handleEditSubscription}
                >
                  Upgrade
                </p>
              )}
            </div>
          </div>
          {/* <div className={styles.account_limit}>
            <p className={styles.account_limit_title}>Ai Credits</p>
            <div className={styles.progress_line_background}>
              <div
                className={styles.progress_line}
                style={{
                  width: `${
                    ((profile?.ai_credits_used || 0) /
                      (currentPlan?.metadata?.ai_credit_limit || 1)) *
                    100
                  }%`,
                }}
              ></div>
            </div>
            <div className={styles.limit_info}>
              {profile?.ai_credits_used || 0} /{" "}
              {currentPlan?.metadata?.ai_credit_limit}
              {otherPlans.length !== 0 && (
                <p
                  className={styles.upgrade_text_button}
                  onClick={handleManageSubscription}
                >
                  Upgrade
                </p>
              )}
            </div>
          </div> */}
        </div>
      </div>
      <div className={styles.payment_information}>
        <div
          className={styles.manage_payment_button}
          onClick={handleManageSubscription}
        >
          Manage Payment
        </div>
        <p className={styles.payment_section_title}>Payment Information</p>
        <div className={styles.card_list}>
          {customerInformation?.payment_methods.map((paymentMethod, index) => (
            <CreditCard paymentMethod={paymentMethod} profile={profile} />
          ))}
          <div className={styles.add_credit_card}>
            <div
              className={styles.add_credit_card_button}
              onClick={handleManageSubscription}
            >
              <AddIcon color="rgba(0, 0, 0, 1)" width={20} height={20} />
            </div>
            {/* Add Card */}
          </div>
        </div>
      </div>
      {/* <div className={styles.payment_section_button}>
        <div className={styles.payment_plan_info}>
          <p className={styles.section_title}>Current Plan</p>
          <div className={styles.subscription_options}>
            <CurrentPlanTile profile={profile} />
            <UpgradePlanTile profile={profile} />
          </div>
        </div>
        <div
          className={styles.manage_subscription_button}
          onClick={handleManageSubscription}
        >
          Manage Subscription
        </div>
      </div>
      <div className={styles.payment_section}>
        <p className={styles.section_title}>Payment Methods</p>
        {profile.paymentInfo.paymentMethods.map((paymentMethod, index) => (
          <CreditCard paymentMethod={paymentMethod} />
        ))}
      </div> */}
    </div>
  );
}

function FreePlanTile({ currentPlan }) {
  return (
    <div className={styles.plan_option}>
      <div className={styles.plan_option_top_information}>
        <p className={styles.plan_option_title}>Free Plan</p>
        <p className={styles.plan_option_price}>$0/month</p>
      </div>
      <p className={styles.plan_option_subtitle}>
        {/* {currentPlan?.metadata?.mailbox_limit} Email,{" "} */}
        30 Contacts {/* {currentPlan?.metadata?.ai_credit_limit} Ai Credits */}
      </p>
      <div className={styles.plan_option_bottom_information}>
        <div className={styles.plan_option_status}>Current Plan</div>
        <div className={styles.plan_data_icons}>
          <img
            alt="logo"
            src="/SocialMediaLogos/instagram.png"
            className={styles.data_icon}
          ></img>
          {/* {currentPlan?.name !== "Lite Plan" && (
            <img
              alt="logo"
              src="/SocialMediaLogos/tiktok.png"
              className={styles.data_icon}
            ></img>
          )}
          {currentPlan?.name !== "Lite Plan" && (
            <img
              alt="logo"
              src="/SocialMediaLogos/youtube.png"
              className={styles.youtube_icon}
            ></img>
          )} */}
        </div>
      </div>
    </div>
  );
}

function CurrentPlanTile({ currentPlan }) {
  return (
    <div className={styles.plan_option}>
      <div className={styles.plan_option_top_information}>
        <p className={styles.plan_option_title}>
          {extractFirstWord(currentPlan?.name)}
        </p>
        <p className={styles.plan_option_price}>$120/month</p>
      </div>
      <p className={styles.plan_option_subtitle}>
        {/* {currentPlan?.metadata?.mailbox_limit} Email,{" "} */}
        {currentPlan?.metadata?.contact_search_limit} Contacts{" "}
        {/* {currentPlan?.metadata?.ai_credit_limit} Ai Credits */}
      </p>
      <div className={styles.plan_option_bottom_information}>
        <div className={styles.plan_option_status}>Current Plan</div>
        <div className={styles.plan_data_icons}>
          <img
            alt="logo"
            src="/SocialMediaLogos/instagram.png"
            className={styles.data_icon}
          ></img>
          {currentPlan?.name !== "Lite Plan" && (
            <img
              alt="logo"
              src="/SocialMediaLogos/tiktok.png"
              className={styles.data_icon}
            ></img>
          )}
          {currentPlan?.name !== "Lite Plan" && (
            <img
              alt="logo"
              src="/SocialMediaLogos/youtube.png"
              className={styles.youtube_icon}
            ></img>
          )}
        </div>
      </div>
    </div>
  );
}

function UpgradePlanTile(props) {
  const profile = props.profile;
  return (
    <div className={styles.subscription_option_upgrade}>
      <div className={styles.subscription_option_top}>
        <p className={styles.plan_type}>Premium</p>
        <p className={styles.price_point}>
          <span className={styles.bolder}>
            {formatPrice(profile.paymentInfo.pricePoint)}
          </span>
          /{profile.paymentInfo.interval}
        </p>
      </div>
      {/* <div className={styles.subscription_option_middle}>
        <p className={styles.plan_renews}>
          Your plan renews on{" "}
          {formatUnixTimestamp(profile.paymentInfo.billingCycle[1])}
        </p>
      </div> */}
      <div className={styles.subscription_option_bottom}>
        <div className={styles.upgrade_button}>Upgrade</div>
        {/* <p className={styles.learn_more}>Learn More</p> */}
      </div>
    </div>
  );
}

function formatUnixTimestamp(timestamp) {
  const date = new Date(timestamp * 1000);

  return date.toLocaleDateString("en-US", {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
}

function formatPrice(priceInCents, currency = "USD") {
  const priceInDollars = priceInCents / 100;

  return new Intl.NumberFormat("en-US", {
    style: "currency",
    currency: currency,
  }).format(priceInDollars);
}

const CreditCard = ({ paymentMethod, profile }) => {
  return (
    <div className={styles.credit_card}>
      <div className={styles.card_top_info}>
        <img
          className={styles.chip_image}
          src="/PaymentOptionsImages/chip.png"
          alt="brand"
        ></img>

        <img
          className={styles.card_brand_image}
          src="/PaymentOptionsImages/visaImage.png"
          alt="brand"
        ></img>
      </div>
      <div className={styles.card_middle}>
        <p className={styles.card_number}>
          •••• •••• •••• {paymentMethod.last4}
        </p>
      </div>
      <div className={styles.card_bottom_info}>
        <div className={styles.card_info_left}>
          <p className={styles.card_title}>Name</p>
          <p className={styles.card_value}>
            {profile.firstName} {profile.lastName}
          </p>
        </div>
        <div className={styles.card_info_right}>
          <p className={styles.card_title}>Expires</p>
          <p className={styles.card_value}>
            {paymentMethod.exp_month} / {paymentMethod.exp_year}
          </p>
        </div>
      </div>
    </div>
  );
};

const extractFirstWord = (plan) => {
  if (plan) {
    return plan.split(" ")[0];
  }
};
