import React from "react";
import styles from "./page.module.css";

function PageControl({ prevFunction, nextFunction }) {
  return (
    <div className={styles.page_controls}>
      <div className={styles.page_control} onClick={prevFunction}>
        Prev Page
      </div>
      <div className={styles.page_control} onClick={nextFunction}>
        Next Page
      </div>
    </div>
  );
}

export default PageControl;
