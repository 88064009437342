export const platform_filter_options = {
  PLATFORM: {
    platform: {
      title: "Platform",
      type: "platform",
      value: "Instagram",
      id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
      logo: "/images/social_icons/instagram.png",
      platform_options: [
        {
          id: "9bb8913b-ddd9-430b-a66a-d74d846e6c66",
          name: "Instagram",
          logo_url: "/images/social_icons/instagram.png",
          url: "https://www.instagram.com/",
          premium: false,
          products: {
            identity: {
              is_supported: true,
              audience: {
                is_supported: true,
              },
            },
            engagement: {
              is_supported: true,
              audience: {
                is_supported: true,
              },
            },
            income: {
              is_supported: false,
            },
            switch: {
              is_supported: false,
            },
            activity: {
              is_supported: false,
            },
            publish: {
              is_supported: false,
            },
          },
        },
        {
          id: "de55aeec-0dc8-4119-bf90-16b3d1f0c987",
          name: "TikTok",
          logo_url: "/images/social_icons/tiktok.png",
          url: "https://www.tiktok.com",
          premium: true,
          products: {
            identity: {
              is_supported: true,
              audience: {
                is_supported: false,
              },
            },
            engagement: {
              is_supported: true,
              audience: {
                is_supported: false,
              },
            },
            income: {
              is_supported: false,
            },
            switch: {
              is_supported: false,
            },
            activity: {
              is_supported: false,
            },
            publish: {
              is_supported: false,
            },
          },
        },
        {
          id: "14d9ddf5-51c6-415e-bde6-f8ed36ad7054",
          name: "YouTube",
          logo_url: "/images/social_icons/youtube.png",
          url: "https://www.youtube.com/",
          premium: true,
          products: {
            identity: {
              is_supported: true,
              audience: {
                is_supported: true,
              },
            },
            engagement: {
              is_supported: true,
              audience: {
                is_supported: true,
              },
            },
            income: {
              is_supported: true,
            },
            switch: {
              is_supported: false,
            },
            activity: {
              is_supported: false,
            },
            publish: {
              is_supported: false,
            },
          },
        },
      ],
    },
  },
};

export const filter_options = {
  CREATOR: {
    followers: {
      title: "Followers",
      type: "range",
      default: "true",
      dropdown_options: [
        [
          "From",
          1000,
          5000,
          10000,
          25000,
          50000,
          100000,
          250000,
          500000,
          ">1M",
        ],
        ["To", 1000, 5000, 10000, 25000, 50000, 100000, 250000, 500000, "<1M"],
      ],
    },
    subscribers: {
      title: "Subscribers",
      type: "range",
      default: "true",
      dropdown_options: [
        [
          "From",
          1000,
          5000,
          10000,
          25000,
          50000,
          100000,
          250000,
          500000,
          ">1M",
        ],
        ["To", 1000, 5000, 10000, 25000, 50000, 100000, 250000, 500000, "<1M"],
      ],
    },
    topic: {
      title: "Topics",
      type: "input",
    },
    keyword: {
      title: "Keyword",
      type: "input",
      single_input: true,
    },
    specific_contact_details: {
      title: "Email Preference",
      type: "EMAIL",
    },
    brand_affinity: {
      title: "Brand Affinity",
      type: "search-input",
      collection: "brands",
    },
    amount_of_content: {
      title: "Amount of Content",
      type: "range",
      dropdown_options: [
        ["From", 100, 200, 300, 400, 500, 1000, 5000, ">10k"],
        ["To", 100, 200, 300, 400, 500, 1000, 5000, "<10k"],
      ],
    },
    gender: {
      title: "Gender",
      type: "dropdown",
      dropdown_options: [["All", "Male", "Female"]],
    },
    age: {
      title: "Age",
      type: "range",
      dropdown_options: [
        ["From", 13, 18, 25, 35, 45, 65],
        ["To", 17, 24, 34, 44, 64],
      ],
    },
    verified: {
      title: "Verified",
      type: "dropdown",
      default: "true",
      dropdown_options: [["False", "True"]],
    },
    recent_post: {
      title: "Most Recent Post",
      type: "dropdown",
      dropdown_options: [["<1 Month", "<3 Month", "<6 Month"]],
    },
    creator_language: {
      title: "Creator Language",
      type: "search-input",
      collection: "languages",
      single_input: true,
    },
    creator_interests: {
      title: "Creator Interests",
      type: "search-input",
      collection: "interests",
    },
    creator_location: {
      title: "Creator Location",
      type: "search-input",
      collection: "locations",
      default: "true",
    },
    account_type: {
      title: "Account Type",
      type: "dropdown",
      dropdown_options: [["All", "Business", "Creator", "Personal"]],
    },
    hashtags: {
      title: "Hashtags",
      type: "input",
    },
    mentions: {
      title: "Mentions",
      type: "input",
    },
  },
  AUDIENCE: {
    audience_location: {
      title: "Audience Location",
      type: "search-input",
      collection: "locations",
      slider: true,
      single_input: true,
      default: "true",
    },
    audience_age: {
      title: "Audience Age",
      type: "range",
      slider: true,
      default: "true",
      dropdown_options: [
        ["From", 13, 18, 25, 35, 45, 65],
        ["To", 17, 24, 34, 44, 64],
      ],
    },
    audience_gender: {
      title: "Audience Gender",
      type: "dropdown",
      slider: true,
      default: "true",
      dropdown_options: [["All", "Male", "Female"]],
    },
    audience_language: {
      title: "Audience Language",
      type: "search-input",
      single_input: true,
      slider: true,
      collection: "languages",
    },
    audience_interests: {
      title: "Audience Interests",
      type: "search-input",
      collection: "interests",
    },
    audience_brand_affinity: {
      title: "Audience Brand Affinity",
      type: "search-input",
      collection: "brands",
    },
  },
  PERFORMANCE: {
    average_likes: {
      title: "Average Likes",
      type: "range",
      dropdown_options: [
        [
          "From",
          100,
          200,
          300,
          400,
          500,
          1000,
          5000,
          50000,
          100000,
          500000,
          ">1M",
        ],
        [
          "To",
          100,
          200,
          300,
          400,
          500,
          1000,
          5000,
          50000,
          100000,
          500000,
          "<1M",
        ],
      ],
    },
    engagement_rate: {
      title: "Engagement Rate",
      type: "slider",
    },
    has_sponsored: {
      title: "Has Sponsored",
      type: "dropdown",
      dropdown_options: [["False", "True"]],
    },
    // reel_views: {
    //   title: "Reel Views",
    //   type: "range",
    //   dropdown_options: [
    //     [
    //       "From",
    //       1000,
    //       5000,
    //       10000,
    //       25000,
    //       50000,
    //       100000,
    //       250000,
    //       500000,
    //       ">1M",
    //     ],
    //     ["To", 1000, 5000, 10000, 25000, 50000, 100000, 250000, 500000, "<1M"],
    //   ],
    // },
    month_growth: {
      title: "Follower Growth",
      type: "dropdown",
      slider: true,
      dropdown_options: [
        [
          "In last",
          "1 Month",
          "2 Months",
          "3 Month",
          "4 Months",
          "5 Month",
          "6 Months",
        ],
      ],
    },
  },
};

// export const phyllo_filter_options = {
//   social_platform: {
//     type: "dropdown",
//     dropdownData: [
//       { name: "Instagram", image: "InstagramImage.png" },
//       { name: "YouTube", image: "YoutubeImage.png" },
//       { name: "TikTok", image: "TikTokImage.png" },
//     ],
//   },
//   sort_by: {
//     type: "dropdown",
//     dropdownData: [
//       { name: "Instagram", image: "InstagramImage.png" },
//       { name: "YouTube", image: "YoutubeImage.png" },
//       { name: "TikTok", image: "TikTokImage.png" },
//     ],
//     order: "DESCENDING",
//   },

//   follower_count: {
//     min: 0,
//     max: 0,
//   },

//   sort_by: {
//     field: "AVERAGE_LIKES",
//     order: "DESCENDING",
//   },
//   limit: 10,
//   offset: 0,
// };
