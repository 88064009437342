import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";

import { PhyllloContext } from "../../../context/PhylloContext";

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(0) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(0) + "K";
  } else {
    return num;
  }
}

function findCurrentFilter(filterOptions, filterTitle) {
  let currentFilter = null;

  for (const sectionKey in filterOptions) {
    if (Object.hasOwnProperty.call(filterOptions, sectionKey)) {
      const section = filterOptions[sectionKey];

      for (const filterKey in section) {
        if (
          Object.hasOwnProperty.call(section, filterKey) &&
          section[filterKey].title === filterTitle
        ) {
          currentFilter = section[filterKey];
          break;
        }
      }

      if (currentFilter) {
        break;
      }
    }
  }

  return currentFilter;
}

const updateFilterValues = (filterOptions, filterTitle, value, sliderValue) => {
  const updatedFilterOptions = { ...filterOptions };

  for (const sectionKey in updatedFilterOptions) {
    if (Object.hasOwnProperty.call(updatedFilterOptions, sectionKey)) {
      const section = updatedFilterOptions[sectionKey];

      for (const filterKey in section) {
        if (
          Object.hasOwnProperty.call(section, filterKey) &&
          section[filterKey].title === filterTitle
        ) {
          if (value === "All" || value === "False" || value.length === 0) {
            delete section[filterKey].value;
            delete section[filterKey].sliderValue;
          } else {
            section[filterKey].value = value;
            if (sliderValue) {
              section[filterKey].sliderValue = sliderValue;
            }
          }
        }
      }
    }
  }

  return updatedFilterOptions;
};

const updateFilterSlideValue = (filterOptions, filterTitle, value) => {
  const updatedFilterOptions = { ...filterOptions };

  for (const sectionKey in updatedFilterOptions) {
    if (Object.hasOwnProperty.call(updatedFilterOptions, sectionKey)) {
      const section = updatedFilterOptions[sectionKey];

      for (const filterKey in section) {
        if (
          Object.hasOwnProperty.call(section, filterKey) &&
          section[filterKey].title === filterTitle
        ) {
          section[filterKey].sliderValue = value;
        }
      }
    }
  }

  return updatedFilterOptions;
};

export function RangeComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );
  const [sliderValue, setSliderValue] = useState(
    currentFilter && currentFilter.sliderValue ? currentFilter.sliderValue : 20
  );

  const sliderText =
    props.filter.title === "Audience Gender" ? "gender" : "age";

  const [selectedValues, setSelectedValues] = useState(
    currentFilter && currentFilter.value
      ? [currentFilter.value[0] || "From", currentFilter.value[1] || "To"]
      : [
          props.filter.dropdown_options[0][0],
          props.filter.dropdown_options[1][0],
        ]
  );

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [isDropdownOpen2, setIsDropdownOpen2] = useState(false);

  const dropdownRef1 = useRef(null);
  const dropdownRef2 = useRef(null);

  const dropdown_options_one = props.filter.dropdown_options[0];
  const dropdown_options_two = props.filter.dropdown_options[1];

  const showSlider =
    props.filter.slider &&
    (selectedValues[0] !== "From" || selectedValues[1] !== "To");

  const updateValues = (value) => {
    let updatedFilterOptions;

    if (props.filter.slider) {
      updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        value,
        sliderValue
      );
    } else {
      updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        value
      );
    }

    props.setFilterOptions(updatedFilterOptions);
  };

  useEffect(() => {
    if (selectedValues[0] === "From" && selectedValues[1] === "To") {
      updateValues([]);
    } else {
      const filteredValues = selectedValues.map((value) =>
        value === "From" || value === "To" ? null : value
      );
      updateValues(filteredValues);
    }
  }, [selectedValues]);

  const handleSelect1 = (value) => {
    setSelectedValues((prevValues) => [value, prevValues[1]]);
    setIsDropdownOpen1(false);
  };

  const handleSelect2 = (value) => {
    setSelectedValues((prevValues) => [prevValues[0], value]);
    setIsDropdownOpen2(false);
  };

  const handleClick1 = () => {
    setIsDropdownOpen1(!isDropdownOpen1);
    if (isDropdownOpen2) setIsDropdownOpen2(false);
  };

  const handleClick2 = () => {
    setIsDropdownOpen2(!isDropdownOpen2);
    if (isDropdownOpen1) setIsDropdownOpen1(false);
  };

  const formatNumbericalValues = (value) => {
    if (typeof value === "number") {
      return formatNumber(value);
    }
    return value;
  };

  const handleSliderValueUpdate = (value) => {
    const updatedFilterOptions = updateFilterSlideValue(
      props.filterOptions,
      props.filter.title,
      value
    );

    props.setFilterOptions(updatedFilterOptions);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsDropdownOpen1(false);
      }
      if (
        dropdownRef2.current &&
        !dropdownRef2.current.contains(event.target)
      ) {
        setIsDropdownOpen2(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1, dropdownRef2]);

  return (
    <div className={styles.filter_dropdown_range_component}>
      <div className={styles.filter_range_dropdown_component}>
        <div
          className={styles.range_dropdown_box}
          onClick={handleClick1}
          ref={dropdownRef1}
        >
          <p className={styles.filter_value_dropdown}>
            {formatNumbericalValues(selectedValues[0])}
          </p>
          <img
            src="/images/general_icons/DownArrowIcon.png"
            alt="dropdown"
            className={styles.dropdown_arrow}
          />
          {isDropdownOpen1 && (
            <DropDownMenu
              options={dropdown_options_one}
              onSelect={handleSelect1}
              className={styles.dropdown_below}
            />
          )}
        </div>

        <div
          className={styles.range_dropdown_box}
          onClick={handleClick2}
          ref={dropdownRef2}
        >
          <p className={styles.filter_value_dropdown}>
            {formatNumbericalValues(selectedValues[1])}
          </p>
          <img
            src="/images/general_icons/DownArrowIcon.png"
            alt="dropdown"
            className={styles.dropdown_arrow}
          />
          {isDropdownOpen2 && (
            <DropDownMenu
              options={dropdown_options_two}
              onSelect={handleSelect2}
              className={styles.dropdown_below}
            />
          )}
        </div>
      </div>
      {showSlider && (
        <div className={styles.slider_component}>
          <p className={styles.slider_dropdown_text}>
            More than{" "}
            <strong className={styles.slider_dropdown_value_text}>
              {sliderValue}%
            </strong>{" "}
            of audience's {sliderText} is <strong>{selectedValues}</strong>
          </p>
          <Slider
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            handleSliderValueUpdate={handleSliderValueUpdate}
          />
        </div>
      )}
    </div>
  );
}

export function TopicInputComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );

  const [selectedOptions, setSelectedOptions] = useState(
    currentFilter && currentFilter.value ? currentFilter.value : []
  );

  const [selectedValue, setSelectedValue] = useState(
    currentFilter && props.filter.single_input && currentFilter.value
      ? currentFilter.value
      : ""
  );

  useEffect(() => {
    setSelectedOptions(
      currentFilter && currentFilter.value ? currentFilter.value : []
    );
    setSelectedValue(
      currentFilter && props.filter.single_input && currentFilter.value
        ? currentFilter.value
        : null
    );
  }, [props.filterOptions]);

  const inputRef = useRef(null);

  const handleSelect = (value) => {
    if (!selectedOptions.includes(value)) {
      let updatedSelectedOptions = [];

      updatedSelectedOptions = [...selectedOptions, value];
      setSelectedValue("");

      setSelectedOptions(updatedSelectedOptions);
      const updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        updatedSelectedOptions
      );
      props.setFilterOptions(updatedFilterOptions);
    }

    inputRef.current.value = "";
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSelectedValue(inputValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && selectedValue.trim() !== "") {
      event.preventDefault();
      setSelectedValue("");
      handleSelect(selectedValue.trim());
    }
  };

  return (
    <div className={styles.searchbar}>
      <input
        type="text"
        className={styles.searchbar_input}
        onChange={handleInputChange}
        onKeyPress={handleKeyPress}
        placeholder="Enter a topic e.x. Music"
        value={selectedValue}
        ref={inputRef}
      />
      {/* <div className={styles.email_preferance}>
        <div className={styles.line_divider_email}></div>
        <p className={styles.email_preferance_text}>Must have email</p>
        <div className={styles.email_checkbox}></div>
      </div> */}
      <EmailPreferanceRadio
        filter={props.emailPreferanceItem}
        setFilterOptions={props.setFilterOptions}
        filterOptions={props.filterOptions}
      />
    </div>
  );
}

function EmailPreferanceRadio(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );

  const [value, setValue] = useState(
    currentFilter && currentFilter.value ? currentFilter.value : false
  );

  useEffect(() => {
    setValue(
      currentFilter && currentFilter.value ? currentFilter.value : false
    );
  }, [props.filterOptions]);

  const toggleEmailPreference = () => {
    const updatedEmailPreference = !value;
    setValue(updatedEmailPreference);

    const updatedFilterOptions = updateFilterValues(
      props.filterOptions,
      props.filter.title,
      updatedEmailPreference
    );
    props.setFilterOptions(updatedFilterOptions);
  };

  const textClass = !value
    ? styles.email_preferance_text
    : styles.email_preferance_text_enabled;

  const circleClass = !value
    ? styles.email_checkbox
    : styles.email_checkbox_enabled;

  return (
    <div className={styles.email_preferance} onClick={toggleEmailPreference}>
      <div className={styles.line_divider_email}></div>
      <p className={textClass}>Must have email</p>
      <div className={circleClass}>
        {value && <div className={styles.checkbox_fill}></div>}
      </div>
    </div>
  );
}

export function MiniInputComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );

  const [selectedOptions, setSelectedOptions] = useState(
    currentFilter && currentFilter.value ? currentFilter.value : []
  );

  const [selectedValue, setSelectedValue] = useState(
    currentFilter && props.filter.single_input && currentFilter.value
      ? currentFilter.value
      : ""
  );

  useEffect(() => {
    setSelectedOptions(
      currentFilter && currentFilter.value ? currentFilter.value : []
    );
    setSelectedValue(
      currentFilter && props.filter.single_input && currentFilter.value
        ? currentFilter.value
        : null
    );
  }, [props.filterOptions]);

  const inputRef = useRef(null);

  const handleSelect = (value) => {
    if (!selectedOptions.includes(value)) {
      let updatedSelectedOptions = [];

      updatedSelectedOptions = [...selectedOptions, value];
      setSelectedValue("");

      setSelectedOptions(updatedSelectedOptions);
      const updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        updatedSelectedOptions
      );
      props.setFilterOptions(updatedFilterOptions);
    }

    inputRef.current.value = "";
  };

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSelectedValue(inputValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && selectedValue.trim() !== "") {
      event.preventDefault();
      setSelectedValue("");
      handleSelect(selectedValue.trim());
    }
  };

  return (
    <input
      type="text"
      className={styles.mini_searchbar}
      onChange={handleInputChange}
      onKeyPress={handleKeyPress}
      placeholder={props.placeholder}
      value={selectedValue}
      ref={inputRef}
    />
  );
}

export function InputComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );

  const [selectedOptions, setSelectedOptions] = useState(
    currentFilter && currentFilter.value ? currentFilter.value : []
  );

  const [selectedValue, setSelectedValue] = useState(
    currentFilter && props.filter.single_input && currentFilter.value
      ? currentFilter.value
      : null
  );

  useEffect(() => {
    setSelectedOptions(
      currentFilter && currentFilter.value ? currentFilter.value : []
    );
    setSelectedValue(
      currentFilter && props.filter.single_input && currentFilter.value
        ? currentFilter.value
        : null
    );
  }, [props.filterOptions]);

  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);

  const dropdownRef1 = useRef(null);
  const inputRef = useRef(null);

  const handleSelect = (value) => {
    let addedValue;

    if (props.filter.title === "Hashtags") {
      addedValue = "#" + value;
    } else if (props.filter.title === "Mentions") {
      addedValue = "@" + value;
    } else {
      addedValue = value;
    }

    if (!selectedOptions.includes(addedValue)) {
      let updatedSelectedOptions = [];

      if (props.filter.single_input) {
        updatedSelectedOptions = [addedValue];
        setSelectedValue(addedValue);
      } else {
        updatedSelectedOptions = [...selectedOptions, addedValue];
        setSelectedValue("");
      }
      setSelectedOptions(updatedSelectedOptions);
      const updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        updatedSelectedOptions
      );
      props.setFilterOptions(updatedFilterOptions);
    }

    inputRef.current.value = "";
    setIsDropdownOpen1(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsDropdownOpen1(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSelectedValue(inputValue);
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && selectedValue.trim() !== "") {
      event.preventDefault();
      setSelectedValue("");
      handleSelect(selectedValue.trim());
    }
  };

  const handleFocus = () => {
    setIsDropdownOpen1(true);
  };

  const handleBlur = () => {
    if (!dropdownRef1.current.contains(document.activeElement)) {
      setIsDropdownOpen1(false);
    }
  };

  const removeOption = (indexToRemove) => {
    // Remove the item at the specified index
    if (props.filter.single_input) {
      setSelectedValue("");
    }
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions.splice(indexToRemove, 1);
    setSelectedOptions(updatedSelectedOptions);

    // Update the filter options
    const updatedFilterOptions = updateFilterValues(
      props.filterOptions,
      props.filter.title,
      updatedSelectedOptions
    );
    props.setFilterOptions(updatedFilterOptions);
  };

  let searchPlaceholderText;

  switch (props.filter.title) {
    case "Topics":
      searchPlaceholderText = "Add Topics";
      break;
    case "Keyword":
      searchPlaceholderText = "Search by keyword";
      break;
    case "Creator Location":
      searchPlaceholderText = "Search Locations";
      break;
    // Add more cases as needed for additional filter titles
    default:
      searchPlaceholderText = "Search"; // Default placeholder text for unknown titles
  }

  return (
    <div className={styles.filter_dropdown_component}>
      <div className={styles.input_dropdown_box} ref={dropdownRef1}>
        <input
          type="text"
          className={styles.filter_value}
          onChange={handleInputChange}
          onKeyPress={handleKeyPress} // Listen for Enter key press
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder={searchPlaceholderText}
          value={selectedValue} // Bind the value to selectedValue
          ref={inputRef}
        />
      </div>
      {selectedOptions.length > 0 && (
        <div className={styles.selected_options_list}>
          {selectedOptions.map((option, index) => (
            <div key={index} className={styles.selected_options_item}>
              <p className={styles.selected_options_text}> {option}</p>
              <img
                src="/cancel-icon.png"
                alt="remove"
                className={styles.selected_options_remove}
                onClick={() => removeOption(index)}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
}

export function SearchInputComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );

  const [selectedOptions, setSelectedOptions] = useState(
    currentFilter && currentFilter.value ? currentFilter.value : []
  );

  const [sliderValue, setSliderValue] = useState(
    currentFilter && currentFilter.sliderValue ? currentFilter.sliderValue : 20
  );

  const [selectedValue, setSelectedValue] = useState(
    currentFilter &&
      currentFilter.value &&
      currentFilter.value.length > 0 &&
      valueObjectCheck(currentFilter.value[0]) &&
      props.filter.single_input
      ? valueObjectCheck(currentFilter.value[0])
      : ""
  );

  const showSlider =
    props.filter.slider &&
    props.filter.value &&
    (props.filter.title === "Audience Language" ||
      props.filter.title === "Audience Location");

  const { searchCollection } = useContext(PhyllloContext);
  const [isDropdownOpen1, setIsDropdownOpen1] = useState(false);
  const [options, setOptions] = useState([]);
  const dropdownRef1 = useRef(null);
  //   const [selectedOptions, setSelectedOptions] = useState([]);
  const inputRef = useRef(null);

  const handleSelect = (value) => {
    // Check if the selected option already exists in the array
    if (!selectedOptions.includes(value)) {
      let updatedSelectedOptions = [];

      if (props.filter.single_input) {
        updatedSelectedOptions = [value];
        setSelectedValue(valueObjectCheck(value));
      } else {
        updatedSelectedOptions = [...selectedOptions, value];
        setSelectedValue("");
      }

      setSelectedOptions(updatedSelectedOptions);

      const updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        updatedSelectedOptions
      );
      props.setFilterOptions(updatedFilterOptions);
    }

    inputRef.current.value = "";
    setIsDropdownOpen1(false);
  };

  const handleSliderValueUpdate = (value) => {
    const updatedFilterOptions = updateFilterSlideValue(
      props.filterOptions,
      props.filter.title,
      value
    );

    props.setFilterOptions(updatedFilterOptions);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setIsDropdownOpen1(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  // Debounce function (you can adjust the delay as needed)
  const debounce = (func, delay) => {
    let debounceTimer;
    const debouncedFunction = function () {
      const context = this;
      const args = arguments;
      clearTimeout(debounceTimer);
      debounceTimer = setTimeout(() => func.apply(context, args), delay);
    };

    // Adding a cancel method to the debounced function
    debouncedFunction.cancel = () => clearTimeout(debounceTimer);

    return debouncedFunction;
  };

  const handleSearch = async (searchInput) => {
    try {
      if (searchInput.trim() === "") {
        return;
      }
      const values = await searchCollection(
        searchInput,
        props.filter.collection
      );

      console.log(values);
      setOptions(values.map((value) => value));
    } catch (error) {
      console.error("Error during location search:", error);
    }
  };

  const debouncedSearch = debounce(handleSearch, 0);

  const handleInputChange = (event) => {
    const inputValue = event.target.value;
    setSelectedValue(inputValue);
    if (inputValue.trim() === "") {
      setOptions([]);
      debouncedSearch.cancel();
    } else {
      debouncedSearch(inputValue);
    }
  };

  const handleFocus = () => {
    setIsDropdownOpen1(true);
  };

  const handleBlur = (event) => {
    setTimeout(() => {
      if (!dropdownRef1.current.contains(document.activeElement)) {
        setIsDropdownOpen1(false);
      }
    }, 100);
  };

  const removeOption = (indexToRemove) => {
    // Remove the item at the specified index
    const updatedSelectedOptions = [...selectedOptions];
    updatedSelectedOptions.splice(indexToRemove, 1);
    setSelectedOptions(updatedSelectedOptions);

    // Update the filter options
    const updatedFilterOptions = updateFilterValues(
      props.filterOptions,
      props.filter.title,
      updatedSelectedOptions
    );
    props.setFilterOptions(updatedFilterOptions);
  };

  let searchPlaceholderText;

  switch (props.filter.title) {
    case "Brand Affinity":
      searchPlaceholderText = "Search Brands";
      break;
    case "Audience Location":
    case "Creator Location":
      searchPlaceholderText = "Search Locations";
      break;
    // Add more cases as needed for additional filter titles
    default:
      searchPlaceholderText = "Search"; // Default placeholder text for unknown titles
  }

  return (
    <div className={styles.filter_dropdown_component}>
      <div className={styles.input_dropdown_box} ref={dropdownRef1}>
        <input
          type="text"
          className={styles.filter_value}
          onChange={handleInputChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          value={selectedValue}
          placeholder={searchPlaceholderText}
          ref={inputRef}
        />
        {isDropdownOpen1 && options.length > 0 && (
          <DropDownMenu
            options={options}
            onSelect={handleSelect}
            className={styles.dropdown_below}
          />
        )}
      </div>
      {selectedOptions.length > 0 && props.filter.single_input !== true && (
        <div className={styles.selected_options_list}>
          {selectedOptions.map((option, index) => (
            <div key={index} className={styles.selected_options_item}>
              <p className={styles.selected_options_text}>
                {valueObjectCheck(option)}
              </p>
              <img
                src="/cancel-icon.png"
                alt="remove"
                className={styles.selected_options_remove}
                onClick={() => removeOption(index)}
              />
            </div>
          ))}
        </div>
      )}
      {showSlider && (
        <div className={styles.slider_component}>
          <p className={styles.slider_dropdown_text}>
            More than{" "}
            <strong className={styles.slider_dropdown_value_text}>
              {sliderValue}%
            </strong>{" "}
            of audience's language is <strong>{selectedValue}</strong>
          </p>
          <Slider
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            handleSliderValueUpdate={handleSliderValueUpdate}
          />
        </div>
      )}
    </div>
  );
}

export function DropdownComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );
  const [sliderValue, setSliderValue] = useState(
    currentFilter && currentFilter.sliderValue ? currentFilter.sliderValue : 20
  );
  const [dropdownOpen, setDropdownOpen] = useState(false);
  //   const [selectedValue, setSelectedValue] = useState();

  const [selectedValue, setSelectedValue] = useState(
    currentFilter && currentFilter.value
      ? currentFilter.value
      : props.filter.dropdown_options[0][0]
  );

  const showSlider =
    props.filter.slider &&
    (selectedValue === "Male" ||
      selectedValue === "Female" ||
      (props.filter.title === "Follower Growth" &&
        selectedValue !== "In last"));
  const dropdownRef1 = useRef(null);
  const dropdown_options_one = props.filter.dropdown_options[0];

  const handleSelectOption = (value) => {
    let updatedFilterOptions;

    if (props.filter.slider) {
      updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        value,
        sliderValue
      );
    } else {
      updatedFilterOptions = updateFilterValues(
        props.filterOptions,
        props.filter.title,
        value
      );
    }

    props.setFilterOptions(updatedFilterOptions);
    setSelectedValue(value);
    setDropdownOpen(false);
  };

  const handleSliderValueUpdate = (value) => {
    const updatedFilterOptions = updateFilterSlideValue(
      props.filterOptions,
      props.filter.title,
      value
    );

    props.setFilterOptions(updatedFilterOptions);
  };

  const handleClick1 = () => {
    setDropdownOpen(!dropdownOpen);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        dropdownRef1.current &&
        !dropdownRef1.current.contains(event.target)
      ) {
        setDropdownOpen(false);
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [dropdownRef1]);

  return (
    <div className={styles.filter_dropdown_component}>
      <div
        className={styles.single_dropdown_box}
        onClick={handleClick1}
        ref={dropdownRef1}
      >
        <p className={styles.filter_value_dropdown}>{selectedValue}</p>
        <img
          src="/images/general_icons/DownArrowIcon.png"
          alt="dropdown"
          className={styles.dropdown_arrow}
        />
        {dropdownOpen && (
          <DropDownMenu
            options={dropdown_options_one}
            onSelect={handleSelectOption}
            className={styles.dropdown_below}
          />
        )}
      </div>
      {showSlider && (
        <div className={styles.slider_component}>
          <p className={styles.slider_dropdown_text}>
            More than{" "}
            <strong className={styles.slider_dropdown_value_text}>
              {sliderValue}%
            </strong>{" "}
            of audience's gender is <strong>{selectedValue}</strong>
          </p>
          <Slider
            sliderValue={sliderValue}
            setSliderValue={setSliderValue}
            handleSliderValueUpdate={handleSliderValueUpdate}
          />
        </div>
      )}
    </div>
  );
}

export function SliderComponent(props) {
  const currentFilter = findCurrentFilter(
    props.filterOptions,
    props.filter.title
  );

  const [sliderValue, setSliderValue] = useState(
    currentFilter && currentFilter.sliderValue ? currentFilter.sliderValue : 0
  );

  const handleSliderValueUpdate = (value) => {
    const updatedFilterOptions = updateFilterValues(
      props.filterOptions,
      props.filter.title,
      value,
      sliderValue
    );
    // const updatedFilterOptions = updateFilterSlideValue(
    //   props.filterOptions,
    //   props.filter.title,
    //   value
    // );

    props.setFilterOptions(updatedFilterOptions);
  };

  return (
    <div className={styles.slider_component}>
      <p className={styles.slider_dropdown_text}>Is more than {sliderValue}%</p>
      <Slider
        sliderValue={sliderValue}
        setSliderValue={setSliderValue}
        handleSliderValueUpdate={handleSliderValueUpdate}
      />
    </div>
  );
}

export function Slider(props) {
  const handleSliderChange = (event) => {
    props.setSliderValue(event.target.value);
    props.handleSliderValueUpdate(event.target.value);
  };

  return (
    <div className={styles.sliderContainer}>
      <input
        type="range"
        min="1"
        max="95"
        value={props.sliderValue}
        className={styles.slider}
        onChange={handleSliderChange}
      />
    </div>
  );
}

export function BoolComponent() {
  return (
    <div className={styles.filter_dropdown_component}>
      <div className={styles.range_dropdown_box}></div>
      <div className={styles.range_dropdown_box}></div>
    </div>
  );
}

function DropDownMenu(props) {
  const formatNumbericalValues = (value) => {
    if (typeof value === "number") {
      return formatNumber(value);
    }
    return value;
  };

  return (
    <div className={styles.dropdown_menu_box}>
      {props.options.map((option) => (
        <div
          key={option}
          className={styles.dropdown_menu_item}
          onClick={() => props.onSelect(option)}
        >
          <p className={styles.dropdown_menu_text}>
            {formatNumbericalValues(valueObjectCheck(option))}
          </p>
        </div>
      ))}
    </div>
  );
}

function valueObjectCheck(input) {
  return typeof input === "object" && input !== null
    ? input.display_name ?? input
    : input;
}
