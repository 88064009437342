import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";
import { PhyllloContext } from "../../../context/PhylloContext";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../../context/AuthContext";
import AddContactIcon from "../../../assets/SVG/AddContactIcon";
import CancelIcon from "../../../assets/SVG/CancelIcon";
import SendEmailIcon from "../../../assets/SVG/SendEmailIcon";
import CheckmarkIcon from "../../../assets/SVG/CheckmarkIcon";
import VerifiedIcon from "../../../assets/SVG/VerifiedIcon";
import CreateEmailPopup from "../../PopUpComponents/CreateEmailPopup/CreateEmailPopup";
import { toast } from "react-toastify";

import BookMarkIcon from "../../../assets/SVG/BookMarkIcon";

import MorePopup from "../UniversalComponents/MorePopup/MorePopUp";

function formatNumber(num) {
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1) + "M";
  } else if (num >= 1000) {
    return (num / 1000).toFixed(1) + "K";
  } else {
    return num;
  }
}

export default function CreatorTile(props) {
  const navigate = useNavigate();

  const { profile, addToContacts, removeFromContacts } =
    useContext(AuthContext);

  const [creatingEmail, setCreatingEmail] = useState(false);

  const { creator, isChecked, onCheck } = props;

  const [isContact, setIsContact] = useState(creator.isContact);

  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const togglePopup = (event) => {
    event.stopPropagation();
    setIsMenuOpen(!isMenuOpen);
  };

  const menuItems = [
    // { label: "Edit", action: () => console.log("Edit action") },
    { label: "Remove Contact", action: () => handleRemoveContact(creator) },
  ];

  function goToProfile() {
    // navigate(`/creator-profile/${encrypt(creator.url)}`);
  }

  function handleSendConnectionRequest(event) {
    event.stopPropagation();
    // sendConnectionRequest(creator);
  }

  function addContactToNewCampaign() {
    props.addContactToNewCampaign(creator);
  }

  function handleCheckboxClick(event) {
    event.stopPropagation();
    props.onCheck(props.index, event.target.checked);
  }

  function connectWithUser(event) {
    event.stopPropagation();
  }
  function stopPropogation(event) {
    event.stopPropagation();
  }

  const tileClass = isChecked
    ? styles.creator_tile_layout_checked
    : styles.creator_tile_layout;

  const creatingCampaign = props.type === "CampaignCreation";

  function openPopup() {
    setCreatingEmail(true);
  }

  function closePopup() {
    setCreatingEmail(false);
  }

  function handleAddToContacts() {
    setIsContact(true);
    addToContacts(creator);
    toast.success("Added To Contacts");
  }

  async function handleRemoveContact() {
    try {
      await removeFromContacts(creator);

      setIsContact(false);
      props.setContacts((prevContacts) =>
        prevContacts.filter(
          (contact) => contact.external_id !== creator.external_id
        )
      );

      toast.success("Removed From Contacts");
    } catch (error) {
      console.error("Error removing contact:", error);
      toast.error("Failed to remove contact.");
    }
  }

  function addContact() {
    //
  }

  const hasEmail = creator?.contact_details?.some(
    (detail) => detail.type === "email"
  );

  const emailAddress = creator?.contact_details?.find(
    (detail) => detail.type === "email"
  );

  const handleNavigate = (url) => {
    window.open(url, "_blank");
  };

  const filteredDetails = creator?.contact_details
    ?.map((detail) => {
      // Skip the details of type "email" or "phone"
      if (detail.type === "email" || detail.type === "phone") {
        return null; // Return null for types to skip
      }
      return detail; // Return the detail object otherwise
    })
    .filter((item) => item !== null); // Filter out the null entries

  return (
    <div className={tileClass} onClick={goToProfile}>
      <CreateEmailPopup
        isOpen={creatingEmail}
        onClose={closePopup}
        activeEmail={profile.connected_accounts[0]}
        connectedInboxes={profile.connected_accounts}
        contact={creator}
        emailAddress={emailAddress?.value || ""}
        // isLoading={isLoading}
      />
      <div className={styles.creator_tile_margin}>
        <div className={styles.checkbox_field}>
          <input
            type="checkbox"
            className={styles.ui_checkbox}
            checked={isChecked}
            onClick={stopPropogation}
            onChange={handleCheckboxClick}
          />
        </div>
        <div className={styles.creator_info}>
          <img
            className={styles.creator_profile_picture}
            src={creator.image_url}
            alt="profile"
          ></img>
          <div className={styles.creator_display_names}>
            <p className={styles.creator_name}>{creator.full_name}</p>
            <p className={styles.creator_username}>
              @{creator.platform_username}
            </p>
            {creator.is_verified && (
              <div className={styles.verified_icon}>
                <VerifiedIcon width={25} height={25} />
              </div>
            )}
          </div>
        </div>
        <div className={styles.tile_section}>
          <p className={styles.creator_stat}>
            {formatNumber(creator.follower_count)}
          </p>
        </div>
        <div className={styles.tile_section}>
          <p className={styles.creator_stat}>
            {formatNumber(creator.average_likes)}
          </p>
        </div>
        {/* <div className={styles.tile_section}>
          <p className={styles.creator_stat}>
            {Number(creator.engagement_rate).toFixed(4)}%
          </p>
        </div> */}
        {/* <div className={styles.tile_section}>
        <p className={styles.creator_stat}>{creator.full_name}</p>
      </div> */}
        {creatingCampaign ? (
          <div className={styles.connect_button_section}>
            <div
              className={styles.add_contact_button}
              onClick={addContactToNewCampaign}
            >
              Add Contact
            </div>
          </div>
        ) : (
          <div className={styles.connect_button_section}>
            {filteredDetails && (
              <div className={styles.connect_button_section}>
                {filteredDetails.map((detail, index) => {
                  const getIconSrc = (type) => {
                    switch (type) {
                      case "instagram":
                        return "https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png";
                      case "facebook":
                        return "https://store-images.s-microsoft.com/image/apps.30645.9007199266245907.cb06f1f9-9154-408e-b4ef-d19f2325893b.ac3b465e-4384-42a8-9142-901c0405e1bc";
                      case "linktree":
                        return "https://asset.brandfetch.io/id_tNIm05N/idJgd2UeGc.png";
                      case "twitter":
                        return "https://upload.wikimedia.org/wikipedia/commons/5/5a/X_icon_2.svg";
                      case "youtube":
                        return "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTFDNolzoTdG8D59gnKUe0CIR4PtMt54QEfFg&s";
                      case "tiktok":
                        return "https://mediarelations.gwu.edu/sites/g/files/zaxdzs5306/files/2024-04/tiktok-7002866_1280.png";
                      default:
                        return "https://thumbs.dreamstime.com/b/mail-icon-flat-style-email-symbol-isolated-white-background-open-envelope-pictogram-black-website-design-app-ui-vector-165006383.jpg";
                    }
                  };

                  return (
                    <div
                      className={styles.connected_contact_account}
                      onClick={() => handleNavigate(detail.value)}
                    >
                      <img
                        className={styles.connected_contact_account_images}
                        src={getIconSrc(detail.type)}
                        alt={detail.type}
                      />
                    </div>
                  );
                })}
              </div>
            )}
            <div
              className={styles.connected_contact_account}
              onClick={() => handleNavigate(creator.url)}
            >
              <img
                className={styles.connected_contact_account_images}
                src="https://upload.wikimedia.org/wikipedia/commons/thumb/a/a5/Instagram_icon.png/2048px-Instagram_icon.png"
                alt="instagram"
              />
            </div>
            {/* {emailAddress && (
              <div className={styles.send_email_button} onClick={openPopup}>
                Email
                <SendEmailIcon />
              </div>
            )} */}

            {!props.isContactsPage ? (
              <>
                {isContact ? (
                  <></>
                ) : (
                  <div
                    className={styles.send_email_button}
                    onClick={handleAddToContacts}
                  >
                    <BookMarkIcon
                      width={15}
                      height={15}
                      color="rgba(0, 0, 0, 1)"
                    />
                  </div>
                )}
              </>
            ) : (
              <div
                className={styles.more_button}
                onClick={(e) => togglePopup(e)}
              >
                <MorePopup
                  items={menuItems}
                  isOpen={isMenuOpen}
                  togglePopup={togglePopup}
                  closeMenu={() => setIsMenuOpen(false)}
                />
                <div className={styles.more_dot}></div>
                <div className={styles.more_dot}></div>
                <div className={styles.more_dot}></div>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
