import { useState, useRef, useEffect, useContext } from "react";
import React from "react";
import styles from "./creator_search.module.css";

import { PhyllloContext } from "../../../context/PhylloContext";
import { phyllo_filter_options } from "./phyllo_filter_options";
import {
  RangeComponent,
  InputComponent,
  DropdownComponent,
  BoolComponent,
  SliderComponent,
  SearchInputComponent,
} from "./FilterDroptownTypes";

export default function FiltersSidebar(props) {
  const FILTER_OPTIONS = props.filterOptions;
  const [openDropdown, setOpenDropdown] = useState([]);

  const [hoveredItem, setHoveredItem] = useState(null);

  const filterSidebarRef = useRef();

  const handleClickOutside = (event) => {
    if (
      filterSidebarRef.current &&
      !filterSidebarRef.current.contains(event.target)
    ) {
      closeFilterSidebar();
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  //Allow Multiple Open Dropdown Filter Menu's
  // const toggleDropdown = (itemKey) => {
  //   if (openDropdown.includes(itemKey)) {
  //     setOpenDropdown(openDropdown.filter((key) => key !== itemKey));
  //   } else {
  //     setOpenDropdown([...openDropdown, itemKey]);
  //   }
  // };

  //Allow Only One Open Dropdown Filter Menu's
  const toggleDropdown = (itemKey) => {
    if (openDropdown === itemKey) {
      setOpenDropdown(null);
    } else {
      setOpenDropdown(itemKey);
    }
  };

  function closeFilterSidebar() {
    console.log("Click One");
    if (props.isVisible) {
      console.log("Click Two");
      props.toggleSidebar();
      setOpenDropdown(null);
    }
  }

  function fetchResults() {
    closeFilterSidebar();
    props.handleCreatorSearch();
  }

  // The two images you want to toggle between
  const defaultImage = "images/general_icons/PlusIcon.png";
  const hoverImage = "images/general_icons/PlusIconBlue.png";

  return (
    <div
      ref={filterSidebarRef}
      className={`${styles.filter_sidebar} ${
        props.isVisible ? styles.visible : ""
      }`}
    >
      <div className={styles.filter_header}>
        <p className={styles.filter_header_title}>All Filters</p>
        <img
          src="images/general_icons/CloseIcon.png"
          alt="close"
          className={styles.close_icon}
          onClick={closeFilterSidebar}
        ></img>
      </div>
      <div className={styles.filter_content}>
        {Object.keys(FILTER_OPTIONS).map((filter_section_key) => {
          if (filter_section_key === "PLATFORM") {
            return null;
          }
          const filter_section = FILTER_OPTIONS[filter_section_key];

          return (
            <div className={styles.filter_column} key={filter_section_key}>
              <div className={styles.section_title}>
                <p className={styles.section_title_text}>
                  {filter_section_key}
                </p>
              </div>
              {Object.keys(filter_section).map((item_key) => {
                const item = filter_section[item_key];

                const isOpen = openDropdown === item_key;

                const isHighlighted = hoveredItem === item_key || isOpen;

                const imageSrc = isHighlighted ? hoverImage : defaultImage;

                const itemTitleClass = isHighlighted
                  ? `${styles.item_title} ${styles.item_title_hovered}`
                  : styles.item_title;

                if (
                  (FILTER_OPTIONS.PLATFORM.platform.value === "Instagram" ||
                    FILTER_OPTIONS.PLATFORM.platform.value === "TikTok") &&
                  item.title === "Subscribers"
                ) {
                  return null;
                }

                // Hide "Followers" for YouTube
                if (
                  FILTER_OPTIONS.PLATFORM.platform.value === "YouTube" &&
                  item.title === "Followers"
                ) {
                  return null;
                }

                return (
                  <React.Fragment key={item_key}>
                    <div className={styles.filter_cell}>
                      {item.value ? (
                        <div
                          className={styles.filter_item_selected}
                          onClick={() => toggleDropdown(item_key)}
                        >
                          <img
                            src="/images/general_icons/CheckmarkIcon.png"
                            alt="selected"
                            className={styles.filter_add_icon}
                          />
                          <p className={styles.item_title_hovered}>
                            {item.title}
                          </p>
                        </div>
                      ) : (
                        <div
                          className={styles.filter_item}
                          onClick={() => toggleDropdown(item_key)}
                          onMouseEnter={() => setHoveredItem(item_key)}
                          onMouseLeave={() => setHoveredItem(null)}
                        >
                          <img
                            src={imageSrc}
                            alt="open"
                            className={styles.filter_add_icon}
                          />
                          <p className={itemTitleClass}>{item.title}</p>
                        </div>
                      )}
                    </div>
                    {isOpen && (
                      <div className={styles.filter_sidebar_dropdown_box}>
                        {item.type === "range" && (
                          <RangeComponent
                            filter={item}
                            setFilterOptions={props.setFilterOptions}
                            filterOptions={props.filterOptions}
                          />
                        )}
                        {item.type === "input" && (
                          <InputComponent
                            filter={item}
                            setFilterOptions={props.setFilterOptions}
                            filterOptions={props.filterOptions}
                          />
                        )}
                        {item.type === "search-input" && (
                          <SearchInputComponent
                            filter={item}
                            setFilterOptions={props.setFilterOptions}
                            filterOptions={props.filterOptions}
                          />
                        )}
                        {item.type === "dropdown" && (
                          <DropdownComponent
                            filter={item}
                            setFilterOptions={props.setFilterOptions}
                            filterOptions={props.filterOptions}
                          />
                        )}
                        {item.type === "bool" && <BoolComponent />}
                        {item.type === "slider" && (
                          <SliderComponent
                            filter={item}
                            setFilterOptions={props.setFilterOptions}
                            filterOptions={props.filterOptions}
                          />
                        )}
                      </div>
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          );
        })}
      </div>
      <div className={styles.filter_footer}>
        <div className={styles.filter_search_button} onClick={fetchResults}>
          <p>Get Results</p>
        </div>
        <p
          className={styles.remove_filter_button}
          onClick={props.removeAllFilters}
        >
          Remove All Filters
        </p>
      </div>
    </div>
  );
}
